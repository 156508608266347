import api from '../api/v1/index'
import debounce from 'lodash/debounce'

export default {
  namespaced: true,
  state: {
    isActiveModal: false,
  },
  mutations: {
    SET_MODAL_ACTIVITY (state, isActive) {
      state.isActiveModal = isActive
    },
  },
  actions: {
    setModalActivity ({ commit }, isActive) {
      commit('SET_MODAL_ACTIVITY', isActive)
    },
    searchForClients: debounce(function ({ commit }, phoneNumber) {
      api.notifyCall({ phoneNumber, direction: 'Wychodzące', callStart: new Date().toISOString(), received: true })
    }, 500),
  }
}
