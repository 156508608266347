import views from '../const/views'
import { clientStatus, scheduleStatus, anyContainerValue } from './recordStatuses'

const isUserPermitted = (permissionName) => {
  const permissions = JSON.parse(localStorage.getItem('EL-Auth-User')).permissions
  return permissions?.some(p => p.name === permissionName)
}

const getContextMenu = (tableName) => views[tableName].table.menu

const getHeaderButtons = (tableName) => views[tableName].header || []

const getQuickActions = (tableName) => views[tableName].table.actions

const inactiveActions = (action, blockerValue) => {
  // object below includes actions that are disabled depending on blocker value
  // object key should match blockerValue
  const disabledActions = {
    Zaplanuj: ['cancelCourse', 'startCourse', 'finishCourse', 'renewCourse'],
    Zaplanowany: ['editDueDate', 'startCourse', 'deleteCourse', 'renewCourse'],
    Wysłany: ['editDueDate', 'sendCourse', 'deleteCourse', 'sendRouteToDriver', 'renewCourse'],
    Rozpoczęty: ['editDueDate', 'sendCourse', 'startCourse', 'deleteCourse', 'editOrder', 'renewCourse', 'sendRouteToDriver'],
    Wykonany: ['cancelCourse', 'assignContainer', 'assignOrder', 'unassignOrder', 'messageDriver', 'finishCourse', 'editDueDate', 'sendCourse', 'startCourse', 'deleteCourse', 'editOrder'],
    OrderUndeletable: ['deleteOrder'],
    OrderUneditable: ['editOrder'],
    nextCourseCreated: ['renewCourse'],
    BlockedClient: ['blockClient'],
    UnblockedClient: ['unblockClient'],
    BlockedSchedule: ['blockOrderSchedule'],
    UnblockedSchedule: ['unblockOrderSchedule'],
    notAnyContainer: ['assignOrder'],
    isAnyContainer: ['showCourseHistory', 'unassignOrder', 'finishCourse', 'generateInvoice', 'generateCustomServiceInvoice'],
    Zabranie: ['assignContainer', 'copyOrder'],
    Wymiana: ['copyOrder'],
    Transport: ['unassignOrder', 'assignContainer', 'pickupContainer', 'swapContainer', 'assignKpoCardToOrder'],
    Substitution: ['unassignOrder', 'assignKpoCardToOrder'],
    containerUnpickable: ['swapContainer', 'pickupContainer'],
    bdoCardAssigned: ['assignKpoCardToOrder'],
    bdoCardUnssigned: ['unassignKpoCard'],
    allButGenerationConfirmed: ['rejectBdoCard', 'confirmBdoCard'],
    allButTakeoverConfirmed: ['confirmTransport'],
    allButConfirmed: ['generateKpoConfirmation'],
    unableToRevise: ['reviseKpoCard'],
    transportTimeUpdateNotAllowed: ['updateTransportTime'],
    taskDone: ['completeTask'],
    taskNotDone: ['revertTask'],
    courseWithoutAddress: ['editCourseAddress'],
    containerNotReserved: ['findAssignedCourses'],
    orderTemplateTransformed: ['newOrderFromTemplate'],
    notReceipt: ['generateInvoice'],
    notInvoice: ['correctInvoice'],
    invoiceAlreadyCreated: ['generateInvoice'],
    courseWithoutOrder: ['finishCourse'],
    isCorrection: ['correctInvoice'],
    notHaveOrder: ['pickupContainer', 'swapContainer', 'navigateToClient', 'updateContainerNotes'],
    isOrderPaid: ['generatePaymentLink'],
    isInvoiced: ['generatePaymentLink'],
    isLinkGenerated: ['generatePaymentLink'],
    withoutTextConfirmation: ['generateDigitalSignature', 'resendTextConfirmation'],
    settingIsNotUpdatable: ['editSettings'],
    isDoneSwap: ['copyOrder'],
    isDonePlacement: ['moveToTails'],
    orderHasNotContainersOrDebrisTypeMismatch: ['confirmOrderMismatches'],
  }
  // if blocker value array includes action return true
  return disabledActions[blockerValue]?.includes(action.name)
}

const filterInactiveActions = (actions, record, tableName) => {
  const blockers = actionBlockers(record, tableName)
  return blockers?.length
    // filter actions that any of it's blockers returns true
    ? actions?.filter(action => !blockers.some(blockerValue => inactiveActions(action, blockerValue) || (action.permission && !isUserPermitted(action.permission))))
    : actions
}

const getActions = (actions, record, tableName, currentTab) => {
  const filteredByTabActions = filterActionsByCurrentTab(actions, currentTab)
  const inactiveActionsDisabled = disableInactiveActions(filteredByTabActions, record, tableName)
  return inactiveActionsDisabled
}

const filterActionsByCurrentTab = (actions, currentTab) => {
  if (!currentTab) return actions

  return actions.filter(action => {
    if (action.showInTabs?.length) { return action.showInTabs.includes(currentTab) }
    if (action.hideInTabs?.length) { return !action.hideInTabs.includes(currentTab) }
    return true
  })
}

const disableInactiveActions = (actions, record, tableName) => {
  const blockers = actionBlockers(record, tableName)
  actions = actions?.map(action => ({
    ...action,
    // set disabled to true if any blocker returns true
    disabled: blockers?.some(blockerValue => inactiveActions(action, blockerValue))
  }))

  return actions?.sort((a, b) => a.disabled - b.disabled)
}

const actionBlockers = (item, tableName) => {
  const isOrderClientBlocked = clientStatus(item.client?.blocked || item.clientBlocked)
  const isOrderDeletable = !item.deletable && 'OrderUndeletable'
  const isOrderEditable = !item.editableState && 'OrderUneditable'
  const isSwap = item.swapCourseId && 'Wymiana'
  const isNextCourseCreated = !!item.nextCourseId && 'nextCourseCreated'
  const isSubstitution = item.courseType === 'Podstawienie' && !item.swapCourseId && 'Substitution'
  const isPickable = !item?.containerPickable && 'containerUnpickable'
  const hasBdoCard = item?.bdoCardId || item?.swapBdoCardId ? 'bdoCardAssigned' : 'bdoCardUnssigned'
  const allButGenerationConfirmed = item.cardStatus !== 'Potwierdzenie wygenerowane' && 'allButGenerationConfirmed'
  const allButTakeoverConfirmed = item.cardStatus !== 'Zrealizowane przejęcie' && 'allButTakeoverConfirmed'
  const allButConfirmed = item.cardStatus !== 'Zatwierdzona' && 'allButConfirmed'
  const unableToRevise = (item.cardStatus !== 'Odrzucona' &&
    !item.senderName?.replaceAll('-', '').toLowerCase().includes('ekologis')) && 'unableToRevise'
  const isTaskDone = item.done ? 'taskDone' : 'taskNotDone'
  const courseWithoutAddress = !item.orderAddressId && 'courseWithoutAddress'
  const isContainerNotReserved = !item.reserved && 'containerNotReserved'
  const transportTimeUpdateNotAllowed = (item.cardStatus !== 'Zatwierdzona' ||
  item.client?.bdoType !== 'Upoważnienie KPO') && 'transportTimeUpdateNotAllowed'
  const isOrderTemplateTransformed = item.transformed && 'orderTemplateTransformed'
  const isNotReceipt = item.settlementType !== 'Paragon' && 'notReceipt'
  const isNotInvoice = item.settlementType !== 'Faktura' && 'notInvoice'
  const isInvoiceCreated = !!item.invoice && 'invoiceAlreadyCreated'
  const courseWithoutOrder = (!item.orderId || item.orderId === -1) && 'courseWithoutOrder'
  const isCorrection = item.invoiceNumber?.split('/')?.shift() === 'FVK' && 'isCorrection'
  const notHaveOrder = !item.order && 'notHaveOrder'
  const isOrderPaid = ['Zapłacone', 'Zapłacone online [Link]', 'Zapłacone online [Sklep]'].includes(item.paymentStatus) && 'isOrderPaid'
  const isInvoiced = !item.withoutDocuments && 'isInvoiced'
  const withoutTextConfirmation = item.withoutTextConfirmation && 'withoutTextConfirmation'
  const settingIsNotUpdatable = !item.updatable && 'settingIsNotUpdatable'
  const isDoneSwap = item.swapCourseId && item.courseStatus === 'Wykonany' && 'isDoneSwap'
  const isDonePlacement = item.courseType === 'Podstawienie' && item.courseStatus === 'Wykonany' && 'isDonePlacement'
  const orderHasNotContainersOrDebrisTypeMismatch = (!!item.containerType?.id && !!item.container?.containerType?.id ? item.containerType?.id === item.container?.containerType?.id : true) && (!!item.reportedDebrisType?.id && !!item.debrisType?.id ? item.reportedDebrisType?.id === item.debrisType?.id : true) && 'orderHasNotContainersOrDebrisTypeMismatch'

  const blockerValue = {
    bdoCards: [allButGenerationConfirmed, allButTakeoverConfirmed, allButConfirmed, transportTimeUpdateNotAllowed, unableToRevise], // todo this may require update because of new tabs
    clientAppOrders: [isOrderTemplateTransformed],
    clients: [clientStatus(item.blocked)],
    containers: [isContainerNotReserved, notHaveOrder],
    courses: [item.courseStatus, item.courseType, anyContainerValue(item), isSwap, isSubstitution, isPickable, hasBdoCard, courseWithoutAddress, courseWithoutOrder, isNextCourseCreated, withoutTextConfirmation, isDoneSwap, isDonePlacement],
    get coursesWithoutCards() { return [...this.courses] },
    invoices: [isNotReceipt, isNotInvoice, isInvoiceCreated, isCorrection],
    order: [isOrderClientBlocked],
    orders: [isOrderClientBlocked, isOrderDeletable, isOrderEditable, isOrderPaid, isInvoiced, orderHasNotContainersOrDebrisTypeMismatch],
    orderSchedules: [scheduleStatus(item.active)],
    ordersCompleted: [isOrderClientBlocked],
    orderTemplates: [isOrderTemplateTransformed],
    routes: [item.stops ? item.stops[0].course.courseStatus : ''],
    tasks: [isTaskDone],
    settings: [settingIsNotUpdatable],
  }
  return blockerValue[tableName] || null
}

export {
  getQuickActions,
  getContextMenu,
  getHeaderButtons,
  filterInactiveActions,
  disableInactiveActions,
  getActions
}
