import { render, staticRenderFns } from "./TableRow.vue?vue&type=template&id=527a2961&scoped=true&"
import script from "./TableRow.vue?vue&type=script&lang=js&"
export * from "./TableRow.vue?vue&type=script&lang=js&"
import style0 from "./TableRow.vue?vue&type=style&index=0&id=527a2961&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527a2961",
  null
  
)

export default component.exports