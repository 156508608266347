<template>
  <div
    class="details details--full details__single-view-order"
    :class="{'mt-80px' : !data}"
  >
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :prev-route="prevRoute"
      :is-preview="!!data"
      :close-sidebar="isEmptyOrder"
      @closeDetails="closeSidebar"
    >
      <Tabs
        v-if="order.swapOrderId && order.displaySwapScreen"
        :tabs="tabs"
        @goTo="changeOrder"
        class="pl-8 pt-2 details-tabs"
        ref="tabs"
      />
      <v-spacer />
      <ActionButtons
        :actions="actions"
        :item="selectedOrder"
      />
    </DetailsToolbar>
    <div class="px-2">
      <SectionsWrapper
        :details="details"
        :data="selectedOrder"
      >
        <template #default="{ detail, slotData }">
          <component
            :is="detail.component || 'DetailsSection'"
            :detail="getSectionFields(detail)"
            :data="slotData"
            :current-tab="currentTab"
            :table-name="tableName"
            :list="nestedSection(detail.multiple)"
          />
        </template>
      </SectionsWrapper>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import { getBlockerValue, getTableDetails, getTitleFormatted, setTableDetailsView } from '../../utils'
import { disableInactiveActions, filterInactiveActions } from '../../utils/quickActions'
import { mapActions, mapState } from 'vuex'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import singleViewMixin from '../../mixins/singleViewMixin'
import Tabs from '../Layout/Tabs'
import ActionButtons from '../Elements/ActionButtons'
import SectionsWrapper from './Sections/SectionsWrapper'
import DetailsToolbar from './Sections/DetailsToolbar'
import DetailsSection from './Sections/DetailsSection'
import DetailsSectionCourses from './Sections/DetailsSectionCourses'
import DetailsSectionList from './Sections/DetailsSectionList'
import DetailsSectionFiles from './Sections/DetailsSectionFiles'
import DetailsSectionReports from './Sections/DetailsSectionReports'
import DetailsSectionKPO from './Sections/DetailsSectionKPO'
import DetailsSectionOperationHistory from './Sections/DetailsSectionOperationHistory'
import DetailsSectionPaymentByLink from './Sections/DetailsSectionPaymentByLink'
import { Course } from '../../models'

const EMPTY_ORDER_INDICATOR = -1

export default {
  components: {
    Tabs,
    ActionButtons,
    DetailsToolbar,
    SectionsWrapper,
    DetailsSection,
    DetailsSectionCourses,
    DetailsSectionList,
    DetailsSectionFiles,
    DetailsSectionReports,
    DetailsSectionKPO,
    DetailsSectionPaymentByLink,
    DetailsSectionOperationHistory
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin, // prevRoute
    singleViewMixin, // getSectionFields
  ],
  provide: {
    notWebSocketHandled: false
  },
  props: {
    tableName: {
      type: String,
      default: 'orders'
    },
    data: { // for anyContainerPickup
      type: Object,
      required: false
    },
  },
  channels: {
    OrderShowChannel: {
      received (data) {
        this.captureSingleChange(data)
        this.setDetailsSections()
      }
    }
  },
  data: () => ({
    details: {},
    currentTab: 0
  }),
  computed: {
    ...mapState({
      order: state => state.order.entity,
      swapOrder: state => state.order.swapOrder,
    }),
    orderData () {
      return this.data || this.order
    },
    selectedOrder () {
      if (this.isEmptyOrder) {
        return this.emptyOrderData
      }
      const order = [this.orderData, this.swapOrder].find((order) => this.currentTab === order.id)
      return order || { id: this.currentTab }
    },
    getDetailsTitle () {
      if (this.orderData.orderId === EMPTY_ORDER_INDICATOR) return 'Zabranie dowolnego kontenera'
      const title = getTableDetails(this.tableName).title
      const order = this.orderData
      const { displaySwapScreen } = order
      if (typeof displaySwapScreen !== 'undefined' && !displaySwapScreen) delete order.swapOrderRefNumber
      return getTitleFormatted(title, order)
    },
    tabs () {
      const swapOrderText = this.orderData.swapOrderId === EMPTY_ORDER_INDICATOR ? 'Zabranie dowolnego kontenera' : 'Zabranie'
      return [
        { value: this.orderData.id, text: 'Podstawienie' },
        { value: this.orderData.swapOrderId, text: swapOrderText }
      ]
    },
    isBlocked () {
      return getBlockerValue(this.orderData, 'order')
    },
    isEmptyOrder () {
      return this.currentTab === EMPTY_ORDER_INDICATOR || !this.data?.orderId === EMPTY_ORDER_INDICATOR
    },
    emptyOrderData () {
      if (this.$route.name === 'orders') return this.orderData?.swapCourse
      return Object.prototype.hasOwnProperty.call(this.orderData, 'address') ? this.orderData : new Course(this.orderData || this.data)
      // there are two cases:
      // 1. for any container pickup we need to pass data to class,
      // 2. for any container swap orderData is already formatted
      // hasOwnProperty checks if data is already formatted
    },
    actions () {
      const { actions } = getTableDetails(this.tableName) || {}
      return filterInactiveActions(actions, this.selectedOrder, this.tableName)
    },
  },
  mounted () {
    const { id: orderId } = this.$route.params
    if (orderId) {
      this.subscribeSocket('OrderShowChannel', { orderId })
      this.getSingleOrder({ id: orderId, nonSwapView: this.$route.query.nonSwapView || false }) // nonSwapView controls 'displaySwapScreen' flag in order entity from BE
        .then(() => {
          this.currentTab = this.orderData.orderId || this.orderData.id
          this.setDetailsSections()
        })
    } else {
      this.currentTab = this.orderData.orderId
      this.setDetailsSections()
    }
  },
  beforeDestroy () {
    this.clearSingleOrder()
    this.unsubscribeSocket('OrderShowChannel')
  },
  methods: {
    ...mapActions({
      getSwapOrder: 'order/getSwapOrder',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      closeSidebar: 'layout/closeSidebar',
    }),
    setDetailsSections() {
      this.details = setTableDetailsView(this.tableName, this.isEmptyOrder, this.selectedOrder)
    },
    async changeOrder (tab) {
      const { value: orderId } = tab
      this.unsubscribeSocket('OrderShowChannel')
      if (orderId !== EMPTY_ORDER_INDICATOR && orderId !== this.selectedOrder.id) {
        const isSwapOrderRequired = (this.tabs[1].value && !this.swapOrder) ||
        this.swapOrder.id !== this.tabs[1].value
        if (isSwapOrderRequired) await this.getSwapOrder(orderId)
        this.subscribeSocket('OrderShowChannel', { orderId })
      }
      this.currentTab = orderId
      this.setDetailsSections()
    },
    filteredActions (actions, status) {
      return disableInactiveActions(actions, status ? 'BlockedClient' : 'UnblockedClient')
    },
    nestedSection (path) {
      return path ? get(this.selectedOrder, path, []) : []
    },
  }
}
</script>
